import { defineComponent, ref } from "vue";

import Hero from "@/components/hero/index.vue";
import heroBg from "../../assets/images/contact-us-bg.png";

import { GoogleMap, Marker } from "vue3-google-map";
import { httpService } from "../../services/http.service";

export default defineComponent({
  name: "Contact Us",
  components: { Hero, GoogleMap, Marker },
  setup() {
    const fullName = ref(null);
    const email = ref(null);
    const message = ref(null);
    const responseSent = ref(false);
    const errorResponse = ref(false);
    const center = { lat: 8.6856722, lng: 9.0857526 };
    const googleMapsAPIKEY = "AIzaSyCKmNtR_UPUV9krKhPe3kPlAuXSWv3u_fw";

    const submitForm = () => {
      if (fullName.value && email.value && message.value) {
        httpService.post(
          `send-message?name=${fullName.value}&email=${email.value}&content=${message.value}`
        );

        fullName.value = null;
        email.value = null;
        message.value = null;
        responseSent.value = true;

        setTimeout(() => {
          responseSent.value = false;
        }, 3000);
      } else {
        errorResponse.value = true;

        setTimeout(() => {
          errorResponse.value = false;
        }, 4000);

      }
    };

    const heroSlide = [
      {
        title: "Contact Us",
        image: heroBg,
      },
    ];
    return {
      center,
      googleMapsAPIKEY,
      heroSlide,
      fullName,
      email,
      message,
      submitForm,
      responseSent,
      errorResponse
    };
  },
});
