<template>
  <section class="contact-us-page">
    <Hero :slideContent="heroSlide" />

    <div class="main mx-auto max-w-screen-lg py-32 px-6 md:px-20">
      <div class="contact-form">
        <div class="map grid grid-cols-2 gap-10 p-30 mb-20">
          <div class="grid items-center">
            <div class="p-20 address">
              <div class="text-2xl text mb-10">
                <b>Email</b> <br />
                <a href="mailto:info@nasida.na.gov.ng"> info@nasida.na.gov.ng </a>
              </div>
              <div class="text-2xl text mb-10">
                <b>Phone</b> <br />
                <a href="mailto:08130861406"> 08130861406,</a>
                <a href="mailto:08138425768"> 08138425768,</a>
                <a href="mailto:07039591140"> 07039591140,</a>
              </div>
              <div class="text text-2xl">
                <b>Address</b> <br />

                NASIDA HQ, Shendam Road, Lafia, Nasarawa State
              </div>
              <div></div>
              <div
                class="
                  flex
                  items-center
                  justify-between
                  mb-4
                  md:flex-col md:items-start
                  lg:flex-row lg:items-center
                  w-100
                "
                
              >
                <div class="flex items-center space-x-3 md:mt-3 lg:mt-0">
                  <a href="https://facebook.com" target="_blank">
                    <Icon icon="uit:facebook-f" height="20" width="20" />
                  </a>
                  <a href="https://instagram.com" target="_blank">
                    <Icon icon="uil:instagram" height="20" width="20" />
                  </a>
                  <a href="https://twitter.com" target="_blank">
                    <Icon icon="uit:twitter-alt" height="20" width="20" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <GoogleMap
              :api-key="googleMapsAPIKEY"
              style="width: 100%; height: 600px"
              :center="center"
              :zoom="15"
            >
              <Marker :options="{ position: center }" />
            </GoogleMap>
          </div>
        </div>
        <div class="form px-10 lg:p-20">
          <input
            type="name"
            v-model="fullName"
            placeholder="Full Name"
            class="w-full bg-white p-4 py-6"
          />
          <input
            v-model="email"
            type="email"
            placeholder="Email"
            class="w-full bg-white p-4 py-6 my-14"
          />
          <textarea
            v-model="message"
            type="text"
            placeholder="Message"
            class="w-full bg-white p-4 py-6 pb-32 mb-4"
          ></textarea>
          <p class="text-center transition-all text-green-500" v-if="responseSent">Your response has been recorded and we will reach about to you as soon as possible</p>
          <p class="text-center transition-all text-red-500" v-if="errorResponse">Please fill all fields</p>
          <button
            @click="submitForm()"
            class="py-4 w-full text-center mt-10 text-2xl"
          >Send Message</button>
        </div>
      </div>
    </div>
  </section>
</template>
<!--  -->
<script src="./contact-us.page.js"></script>
<style src="./contact-us.page.css" scoped lang="css"></style>
